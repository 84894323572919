import React from 'react'
import { isBlank, isPresent } from 'src/common/ObjectHelper'
import { Helmet } from 'react-helmet'
import { getDefaultIndustryName } from '../Resources/Organization/Data';

function PageTitle({ pageContext }) {
  if (isBlank(pageContext)) {
    return <span />
  }

  let links = [
    {
      rel: 'icon',
      type: 'image/png',
      href: `${pageContext.companyProfile.organization.logo_url}`,
    },
  ]

  const url = pageContext.companyProfile.website_content.website_url
  if (isPresent(url)) {
    links.push({
      rel: 'canonical',
      href: `https://${url}`,
    })
  }

  const organization = pageContext.companyProfile.organization
  const company = organization.company_name

  let description = organization.description
  if (isBlank(description)) {
    const city = organization.address.city
    const industry = getDefaultIndustryName(pageContext).toLowerCase()
    description = `Are you searching for the best ${industry} business in ${city}? ${company} is one of the highest rated ${industry} businesses in the ${city} area. Check out our testimonials and quickly book us online today!`
  }

  return (
    <Helmet link={links}>
      <title>{company}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default PageTitle
