import { createSelector } from 'reselect'
import { fetchBillingPlanApi } from './Api'

export const BILLING_PLAN_NAMESPACE = 'billing_plan'
export const TIER_USAGE_BASE = 'usage base'
export const TIER_SUBSCRIPTION = 'subscription'
export const TIER_FREE = 'free'

const getOrganizationBillingPlan = createSelector(
  state => state[BILLING_PLAN_NAMESPACE],
  plan => plan || {}
)

const getBillingPlanExpanded = createSelector(
  getOrganizationBillingPlan,
  plan => plan.billing_plan || {}
)

const getBillingPlanExpandedData = createSelector(
  getBillingPlanExpanded,
  billing_plan => billing_plan.data || {}
)

export const getBillingPlanTier = createSelector(
  getBillingPlanExpandedData,
  plan => plan.tier || ''
)

export const fetchBillingPlan = {
  fetchingApiFunc: fetchBillingPlanApi,
  selector: getOrganizationBillingPlan,
  dataNamespace: BILLING_PLAN_NAMESPACE,
}
