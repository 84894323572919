import React from 'react'
import { Provider } from 'react-redux'
import { createReduxStoreWithInitialState } from 'data-fetcher'
import { reducer as formReducer } from 'redux-form'
import PageTitle from 'src/components/PageTitle'
import { PageContext } from './src/common/PageContext'
import ThemeProvider from './src/common/ThemeContext'
import WithChatBubble from './src/components/CompanyProfile/Chat/WithChatBubble'
import { getThemePrimaryColor } from './src/Resources/CompanyProfile/Theme/Data'
import { getShowChat } from './src/Resources/WebsiteContent/Data'

let createdStore

const createStore = ({ pageContext: companyProfile }) => {
  const theme = companyProfile.companyProfile.theme

  if (!createdStore) {
    createdStore = createReduxStoreWithInitialState(
      { form: formReducer },
      { theme }
    )
  }
  return createdStore
}

export const wrapPageElement = ({ element, props }) => {
  const isEditPage = props.location.pathname.includes('edit')

  console.log('Page rendered');

  return (
    <Provider store={createStore(props)}>
      <PageContext.Provider value={props.pageContext}>
        <ThemeProvider>
          <PageTitle pageContext={props.pageContext} />
          {isEditPage || !getShowChat(props.pageContext) ?
            element :
            <WithChatBubble color={getThemePrimaryColor(props.pageContext)}>{element}</WithChatBubble>
          }
        </ThemeProvider>
      </PageContext.Provider>
    </Provider>
  )
}
