module.exports.hcWebUrl = function HcWebUrl() {
  return process.env.GATSBY_HOUSECALL_WEB_BASE_URL
}

module.exports.slackPhotoRequestUrl = function SlackPhotoRequestUrl() {
  if (process.env.GATSBY_ENV === 'production') {
    return 'https://hooks.slack.com/services/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'
  }
  return 'https://hooks.slack.com/services/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'
}
