const axios = require('axios')
const inMemoryStore = require('./InMemoryStore')
const { isPresent } = require('./ObjectHelper')
const $ = require('jquery')
const { hcWebUrl } = require('./hcWebUrl')

let requestHeaders = () => {
  let requestHeaders = {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    Authorization: 'Token ' + process.env.GATSBY_ORG_API_KEY,
  }

  let impersonateOrgId = inMemoryStore.read('IMPERSONATE_ORG_ID')
  if (isPresent(impersonateOrgId)) {
    requestHeaders = Object.assign(requestHeaders, {
      ADMINOVERRIDE: impersonateOrgId,
    })
  }

  return requestHeaders
}

class RestAPI {
  static get(requestUrl, token) {
    return axios({
      url: hcWebUrl() + requestUrl,
      headers: requestHeaders(token),
      withCredentials: true,
      method: 'GET',
    })
  }

  static getWithFormData(requestUrl, formData) {
    return axios({
      url: hcWebUrl() + requestUrl,
      headers: requestHeaders(),
      method: 'GET',
      withCredentials: true,
      params: formData,
    })
  }

  static deleteWithFormData(requestUrl, formData) {
    return axios({
      url: hcWebUrl() + requestUrl,
      headers: requestHeaders(),
      method: 'DELETE',
      dataType: 'json',
      withCredentials: true,
      data: formData,
    })
  }

  static delete(requestUrl) {
    return axios({
      url: hcWebUrl() + requestUrl,
      headers: requestHeaders(),
      withCredentials: true,
      method: 'DELETE',
    })
  }

  static postWithFormData(requestUrl, formData) {
    return axios({
      url: hcWebUrl() + requestUrl,
      headers: requestHeaders(),
      method: 'POST',
      withCredentials: true,
      data: formData,
    })
  }

  static updateWithFormData(requestUrl, formData) {
    return axios({
      url: hcWebUrl() + requestUrl,
      headers: requestHeaders(),
      method: 'PUT',
      withCredentials: true,
      data: formData,
    })
  }

  static postToSlack(slackURL, text, headerText) {
    if (typeof window !== `undefined`) {
      const formData = new FormData()
      formData.set('payload', JSON.stringify({
        text: headerText,
        attachments: [{ text }]
      }))

      axios({
        url: slackURL,
        method: 'POST',
        data: formData,
        transformRequest(data, headers) {
          delete headers.post['Content-Type']
          return data
        }
      })
    }
  }
}

module.exports = RestAPI
