import { createSelector } from 'reselect'
import { receivedServerData } from 'data-fetcher'
import { fetchCompanyProfileApi } from './Api'
import { isPresent } from 'src/common/ObjectHelper'

export const COMPANY_PROFILE_NAMESPACE = 'companyProfile'

export const DAYS_IN_A_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const defaultOpenTime = '8:00'
const defaultCloseTime = '17:00'

export const receivedCompanyProfileData = data => receivedServerData(data, COMPANY_PROFILE_NAMESPACE)

export const getCompanyProfile = createSelector(
  state => state[COMPANY_PROFILE_NAMESPACE],
  companyProfile => {
    return companyProfile || {}
  }
)

export const getBusinessHours = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    if (
      isPresent(companyProfile.business_hours) &&
      isPresent(companyProfile.business_hours.business_hours) &&
      isPresent(companyProfile.business_hours.business_hours.data)
    ) {
      let businessHours = {}
      companyProfile.business_hours.business_hours.data.forEach(day => {
        if (day.open_time != day.close_time) {
          businessHours[day.day] = {
            openTime: day.open_time,
            closeTime: day.close_time,
          }
        }
      })
      return businessHours
    } else {
      let businessHours = {}
      DAYS_IN_A_WEEK.forEach(day => {
        businessHours[day] = {
          openTime: defaultOpenTime,
          closeTime: defaultCloseTime,
        }
      })
      return businessHours
    }
  }
)

export const getFAQs = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.faqs || []
  }
)

export const getTestimonials = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.testimonials || []
  }
)

export const getVideo = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.video || {}
  }
)

export const getYoutubeId = createSelector(
  state => getVideo(state),
  video => {
    return video.youtube_id || ''
  }
)

export const getMobileBanner = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.mobile_banner || {}
  }
)

export const getDesktopBanner = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.desktop_banner || {}
  }
)

export const getDesktopBannerUrls = createSelector(
  state => getDesktopBanner(state),
  desktopBanner => {
    let desktopBannerImageLinks = {}

    if (desktopBanner.image) {
      desktopBannerImageLinks = {
        original_url: desktopBanner.image.large_url,
        thumb_url: desktopBanner.image.thumb_url,
      }
    }

    return desktopBannerImageLinks
  }
)

export const getMobileBannerUrls = createSelector(
  state => getMobileBanner(state),
  mobileBanner => {
    let mobileBannerImageLinks = {}

    if (mobileBanner.image) {
      mobileBannerImageLinks = {
        original_url: mobileBanner.image.small_url,
        thumb_url: mobileBanner.image.thumb_url,
      }
    }

    return mobileBannerImageLinks
  }
)

export const getCharity = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.charity || {}
  }
)

export const getCharityTitle = createSelector(
  state => getCharity(state),
  charity => {
    return charity.title || ''
  }
)

export const getCharityUrl = createSelector(
  state => getCharity(state),
  charity => {
    return charity.url || ''
  }
)

export const getCharityDescription = createSelector(
  state => getCharity(state),
  charity => {
    return charity.description || ''
  }
)

export const fetchCompanyProfile = {
  fetchingApiFunc: fetchCompanyProfileApi,
  selector: getCompanyProfile,
  dataNamespace: COMPANY_PROFILE_NAMESPACE,
}
