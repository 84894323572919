class InMemoryStore {
  constructor() {
    this.memoryStorage = {}
  }

  read(key) {
    return this.memoryStorage[key]
  }

  write(key, data) {
    this.memoryStorage[key] = data
  }

  remove(key) {
    delete this.memoryStorage[key]
  }

  clearAll(key) {
    this.memoryStorage = {}
  }
}

const inMemoryStore = new InMemoryStore()
module.exports = inMemoryStore
