import { createSelector } from 'reselect'
import { getCompanyProfile } from '../../CompanyProfile/Data'
import { blue } from '@material-ui/core/colors'
import defaultTheme from './../../../Theme'
import { isPresent } from 'src/common/ObjectHelper'
import {
  getBillingPlanTier,
  TIER_SUBSCRIPTION,
} from 'src/Resources/BillingPlan/Data'
import { getShowChat } from 'src/Resources/WebsiteContent/Data'

const INITIAL_THEME_NAMESPACE = 'theme'
export const defaultPrimaryColor = blue[500]

const getTheme = createSelector(
  state => getCompanyProfile(state),
  companyProfile => companyProfile.theme || {}
)

const getInitialTheme = createSelector(
  state => state[INITIAL_THEME_NAMESPACE],
  initialTheme => initialTheme || {}
)

export const getThemeConfiguration = createSelector(
  state => getTheme(state),
  state => getInitialTheme(state),
  (theme, initialTheme) => {
    const serverTheme =
      theme.theme_configuration || initialTheme.theme_configuration

    return isPresent(serverTheme) ? serverTheme : defaultTheme
  }
)

export const getThemePrimaryColor = createSelector(
  state => getThemeConfiguration(state),
  themeConfig => themeConfig.palette.primary.main
)

export const getEditChatColor = createSelector(
  getThemePrimaryColor,
  getBillingPlanTier,
  getShowChat,
  (primaryColor, planTier, showChat) => planTier === TIER_SUBSCRIPTION && showChat ? primaryColor : '#DBDBDB'
)
