import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { getThemeConfiguration } from '../Resources/CompanyProfile/Theme/Data'
import { connect } from 'react-redux'
import { createMuiTheme } from '@material-ui/core/styles'

function ThemeProvider({ children, theme }) {
  return (
    <MuiThemeProvider theme={createMuiTheme(JSON.parse(JSON.stringify(theme)))}>
      {children}
    </MuiThemeProvider>
  )
}

export default connect(state => ({ theme: getThemeConfiguration(state) }))(
  ThemeProvider
)
