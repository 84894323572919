export function isBlank(object) {
  if (object === false) {
    return false
  } else {
    return (
      (isPlainObject(object) && isEmptyObject(object)) ||
      (isArray(object) && object.length === 0) ||
      (typeof object === 'string' && object.trim() === '') ||
      (!object && object !== 0)
    )
  }
}

export function isPresent(object) {
  return !isBlank(object)
}

export function createDate(year, month, day) {
  let monthIndex = month - 1
  return new Date(year, monthIndex, day)
}

function isPlainObject(obj) {
  const object = {}
  if (!obj || object.toString.call(obj) !== '[object Object]') {
    return false
  }

  const prototype = Object.getPrototypeOf(obj)
  if (!prototype) {
    return true
  }

  const constructor =
    object.hasOwnProperty.call(prototype, 'constructor') &&
    prototype.constructor
  return (
    typeof constructor === 'function' &&
    object.toString.call(constructor) === object.toString.call(Object)
  )
}

function isEmptyObject(obj) {
  for (let _ in obj) {
    return false
  }
  return true
}

function isArray(obj) {
  return Array.isArray(obj)
}
