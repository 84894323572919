import { createSelector } from 'reselect'
import { getCompanyProfile } from '../CompanyProfile/Data'

export const getOrganization = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.organization || {}
  }
)

export const getCompanyName = createSelector(
  state => getOrganization(state),
  organization => {
    return organization.company_name || ''
  }
)

export const getAddress = createSelector(
  state => getOrganization(state),
  organization => {
    return organization.address || {}
  }
)

export const getCompanyLogo = createSelector(
  state => getOrganization(state),
  organization => {
    return organization.logo_url || ''
  }
)

export const getCompanyDescription = createSelector(
  state => getOrganization(state),
  organization => {
    return organization.description || ''
  }
)

export const getCompanyEmail = createSelector(
  state => getOrganization(state),
  organization => {
    return organization.email || ''
  }
)

export const getCompanyPhoneNumber = createSelector(
  state => getOrganization(state),
  organization => {
    return organization.phone_number || ''
  }
)

export const getAddressStreet = createSelector(
  state => getAddress(state),
  address => {
    return address.street || ''
  }
)

export const getAddressStreetLineTwo = createSelector(
  state => getAddress(state),
  address => {
    return address.street_line_2 || ''
  }
)

export const getAddressCity = createSelector(
  state => getAddress(state),
  address => {
    return address.city || ''
  }
)

export const getAddressState = createSelector(
  state => getAddress(state),
  address => {
    return address.state || ''
  }
)

export const getAddressZip = createSelector(
  state => getAddress(state),
  address => {
    return address.zip || ''
  }
)

export const getCompanyWebsite = createSelector(
  state => getOrganization(state),
  organization => organization.website || ''
)

export const getOrgId = createSelector(
  state => getOrganization(state),
  organization => organization.id || ''
)

const getDefaultIndustry = createSelector(
  getOrganization,
  (organization) => {
    return organization.default_industry || {}
  }
)

export const getDefaultIndustryName = createSelector(
  getDefaultIndustry,
  (defaultIndustry) => {
    return defaultIndustry.name || ''
  }
)