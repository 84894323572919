module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"typography":{"useNextVariants":true,"fontFamily":"Open Sans,sans-serif"},"fontFamily":"Open Sans,sans-serif","palette":{"primary":{"main":"#2196f3","contrastDefaultColor":"light"},"secondary":{"main":"#FFFFFF","contrastDefaultColor":"dark"},"error":{"main":"#e91e63"},"text":{"main":"#212121"},"button":{"destructiveLightHover":"#fce4ec","destructive":"#e91e63","destructiveHover":"#c2185b","confirmGreen":"#2e7d32","confirmGreenHover":"#1b5e20"},"background":{"default":"#fff"}},"spacing":{"centerContentMaxWidth":1250},"overrides":{"MuiAppBar":{"colorPrimary":{"backgroundColor":"#212121","color":"white"}},"MuiButton":{"text":{"fontFamily":"'Open Sans', Roboto, Lato, Arial, sans-serif"},"textSecondary":{"color":"#e91e63","backgroundColor":"white","&:hover":{"backgroundColor":"#fce4ec"}}},"MuiTypography":{"button":{"fontSize":14},"caption":{"fontSize":12,"color":"#616161"},"body1":{"fontSize":16,"fontWeight":100,"lineHeight":1.8,"lineSpacing":0},"body2":{"fontSize":16,"fontWeight":400},"subtitle1":{"fontSize":16,"fontWeight":500},"h6":{"fontSize":18,"fontWeight":400},"h5":{"fontSize":24,"fontWeight":400},"h4":{"fontSize":34,"color":"#212121"},"h3":{"fontSize":48,"fontWeight":500,"color":"#212121"}}}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans","Merriweather","Roboto"]}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
