// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-company-profile-company-profile-jsx": () => import("/opt/build/repo/src/components/CompanyProfile/CompanyProfile.jsx" /* webpackChunkName: "component---src-components-company-profile-company-profile-jsx" */),
  "component---src-components-checkout-complete-jsx": () => import("/opt/build/repo/src/components/Checkout/Complete.jsx" /* webpackChunkName: "component---src-components-checkout-complete-jsx" */),
  "component---src-components-checkout-checkout-jsx": () => import("/opt/build/repo/src/components/Checkout/Checkout.jsx" /* webpackChunkName: "component---src-components-checkout-checkout-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

