import { createSelector } from 'reselect'
import { getCompanyProfile } from '../CompanyProfile/Data'

const DEFAULT_BOOKING_TITLE = 'Book a service call'

export const getWebsiteContent = createSelector(
  state => getCompanyProfile(state),
  companyProfile => {
    return companyProfile.website_content || {}
  }
)

export const getBookingTitle = createSelector(
  state => getWebsiteContent(state),
  website_content => {
    return website_content.booking_title || DEFAULT_BOOKING_TITLE
  }
)

export const getDescriptionTitle = createSelector(
  state => getWebsiteContent(state),
  website_content => {
    return website_content.description_title || ''
  }
)

export const getBannerTextTitle = createSelector(
  state => getWebsiteContent(state),
  website_content => {
    return website_content.banner_text_title || ''
  }
)

export const getBannerTextDescription = createSelector(
  state => getWebsiteContent(state),
  website_content => {
    return website_content.banner_text_description || ''
  }
)

export const getShowReviews = createSelector(
  state => getWebsiteContent(state),
  website_content => {
    return website_content.show_reviews
  }
)

export const getShowPhysicalAddress = createSelector(
  state => getWebsiteContent(state),
  website_content => {
    return website_content.show_physical_address === true
  }
)

export const getCompanyWebsite = createSelector(
  state => getWebsiteContent(state),
  website_content => website_content.website_url || ''
)

export const getShowBookingCard = createSelector(
  state => getWebsiteContent(state),
  website_content => website_content.show_booking_card
)

export const getPublishedAt = createSelector(
  state => getWebsiteContent(state),
  website_content => website_content.published_at
)

export const getShowChat = createSelector(
  state => getWebsiteContent(state),
  website_content => website_content.show_chat || false
)
