const { blue, grey, pink, green } = require('@material-ui/core/colors')

const theme = {
  typography: {
    useNextVariants: true,
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  fontFamily: ['Open Sans', 'sans-serif'].join(','),
  palette: {
    primary: {
      main: blue[500],
      contrastDefaultColor: 'light',
    },
    secondary: {
      main: '#FFFFFF',
      contrastDefaultColor: 'dark',
    },
    error: {
      main: pink[500],
    },
    text: {
      main: grey[900],
    },
    button: {
      destructiveLightHover: pink[50],
      destructive: pink[500],
      destructiveHover: pink[700],
      confirmGreen: green[800],
      confirmGreenHover: green[900],
    },
    background: {
      default: '#fff',
    },
  },
  spacing: {
    centerContentMaxWidth: 1250,
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: grey[900],
        color: 'white',
      },
    },
    MuiButton: {
      text: {
        fontFamily: "'Open Sans', Roboto, Lato, Arial, sans-serif",
      },
      textSecondary: {
        color: pink[500],
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: pink[50],
        },
      },
    },
    MuiTypography: {
      button: {
        fontSize: 14,
      },
      caption: {
        fontSize: 12,
        color: grey[700],
      },
      body1: {
        fontSize: 16,
        fontWeight: 100,
        lineHeight: 1.8,
        lineSpacing: 0,
      },
      body2: {
        fontSize: 16,
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
      },
      h6: {
        fontSize: 18,
        fontWeight: 400,
      },
      h5: {
        fontSize: 24,
        fontWeight: 400,
      },
      h4: {
        fontSize: 34,
        color: grey[900],
      },
      h3: {
        fontSize: 48,
        fontWeight: 500,
        color: grey[900],
      },
    },
  },
}

module.exports = theme
