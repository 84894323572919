import React from 'react'
import { useSelector } from 'react-redux'
import { getOrgId } from '../../../Resources/Organization/Data'

const getChatFrontEndDomain = () => {
  let chatFrontEndDomain = 'https://chat.housecallpro.com'
  if (process.env.GATSBY_ENV === 'development') {
    chatFrontEndDomain = 'http://localhost:4001'
  } else if (process.env.GATSBY_ENV === 'staging') {
    chatFrontEndDomain = 'https://staging-chat.housecallpro.com'
  }
  return chatFrontEndDomain
}

// TODO: Replace org uuid with the chat key
function WithChatBubble({ children, color }) {
  const organizationUuid = useSelector(getOrgId)

  if (window && organizationUuid && !window.HousecallChat) {
    const chatScript = document.createElement('script')
    chatScript.id = 'housecall-pro-chat-bubble'
    chatScript.src = `${getChatFrontEndDomain()}/proChat.js`
    chatScript.type = 'text/javascript'
    chatScript.setAttribute('data-color', color)
    chatScript.setAttribute('data-organization', organizationUuid)
    document.body.appendChild(chatScript)
  }

  return children
}

export default React.memo(WithChatBubble)
